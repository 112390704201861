@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-slide > div {
  margin: 0 10px;
}

.disclaimer-text {
  padding-right: 5%;
}

.logo-text {
  font-family: "Noto Serif Old Uyghur", sans-serif;
  /* font-family: "Audiowide", sans-serif; */
  font-weight: 100;
  font-style: normal;
}
